import * as helpers from './helpers'

const defaultListReducerForAdIeraksti = helpers.createListReducer({listViewName: 'ad_ieraksts_list'})
export const ad_ieraksti = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultListReducerForAdIeraksti({state, type, payload, reqId})
}

const defaultEditReducerForAdIeraksts = helpers.createEditReducer({editViewName: 'ad_ieraksts_view', actionsName: 'ad_ieraksts'})
export const ad_ieraksts = (state = helpers.editGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultEditReducerForAdIeraksts({state, type, payload, reqId})
}

const defaultEditReducerForAdDownloadIeraksts = helpers.createEditReducer({editViewName: 'ad_ieraksts_download', actionsName: 'ad_ieraksts_dati'})
export const ad_ieraksts_dati = (state = helpers.editGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultEditReducerForAdDownloadIeraksts({state, type, payload, reqId})
}

const defaultEditReducerForAdReImportIeraksts = helpers.createEditReducer({editViewName: 'ad_reimport_data', actionsName: 'ad_reimport_data'})
export const ad_ieraksts_reimport = (state = helpers.editGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    const result = defaultEditReducerForAdReImportIeraksts({ state, type, payload, reqId })

    switch (type) {
      case 'ad_reimport_data_save_success':
      case 'ad_reimport_data_save_failure':
        return {
          ...result,
          returnType: type
        };
      default:
        return result;
    }
}
