import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import { loadSistemasPazinojumiByLocation } from '../../actions/sistemasPazinojumiActions';
import { getCurrentSupApp } from '../../actions/helpers';

import { subAppRoutes } from '../../routes';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const loadIntervalAmount = 60 * 1000;

class DefaultFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadIntervalId: null,
    };
    this.loadSistemasPazinojumiBySubApp = this.loadSistemasPazinojumiBySubApp.bind(this);
  }

  componentDidMount() {
    this.loadSistemasPazinojumiBySubApp()
    this.startInterval()
  }

  componentWillUnmount() {
    clearInterval(this.state.loadIntervalId)
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    if (location && prevProps.location && prevProps.location.type !== location.type) {
      this.loadSistemasPazinojumiBySubApp();
      this.resetInterval();
    }
  }

  startInterval() {
    this.setState({
      loadIntervalId: setInterval(this.loadSistemasPazinojumiBySubApp, loadIntervalAmount)
    })
  }

  resetInterval() {
    clearInterval(this.state.loadIntervalId)
    this.startInterval()
  }

  getCurrentSubAppFromNavigationLinks() {
    return Object.keys(subAppRoutes).find(k => subAppRoutes[k] === this.props.location.type)
  }

  loadSistemasPazinojumiBySubApp() {
    const { dispatch, location } = this.props
    const currentSubApp = getCurrentSupApp(location) ?? this.getCurrentSubAppFromNavigationLinks()

    dispatch(loadSistemasPazinojumiByLocation(currentSubApp))
  }

  render() {

    // eslint-disable-next-line
    const { children, user, location, sistemasPazinojumiByLocation, ...attributes } = this.props;

    const version = process.env.REACT_APP_VERSION || 'DEV'

    const isTest = version.toLowerCase().includes("test") || version.toLowerCase().includes("dev")

    return (
      <React.Fragment>
        {isTest ? (
          <div className="row w-100">
            <span className="ml-auto mr-auto red margin-5-hor bold">ŠĪ IR TESTA VERSIJA</span>
          </div>
        ) : null}

        <div className="row w-100 text-center">
          {sistemasPazinojumiByLocation.list.map(s => 
            s.teksts ? (
              <span 
                key={s.id} 
                className="d-block ml-0 margin-5-hor w-100" 
                dangerouslySetInnerHTML={{__html: s.teksts }} 
              /> 
            ) : null
          )}
        </div>

        {window.warning_message ? (
          <div className="row w-100">
            <span className="d-block ml-auto mr-auto red margin-5-hor bold">
              {window.warning_message}
            </span> 
          </div>
        ) : null}

        <div className="row w-100 d-flex justify-content-between align-items-center">
          <span className="d-block align-self-start">
            &copy; 2021 LVĢMC
          </span>
          <span className="ml-auto align-self-end">
            <a href="https://uniso.atlassian.net/" target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>Pieteikt kļūdu</a>Versija Nr. 1.{version}
          </span>
        </div>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

const mapStateToProps = ({ user, location, metadata, sistemasPazinojumiByLocation }) => ({ user, location, metadata, sistemasPazinojumiByLocation, query: location.query })

export default DefaultFooter = connect(mapStateToProps)(DefaultFooter)
