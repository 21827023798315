import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading';
import GridWithForm from '../grid/GridWithForm'
import {
    getTextFilterCfg, 
    getDateFilterCfg, 
    dateTimeFormatter,
    getmultiSelectFilterCfg,
} from '../grid/ColumnHelpers'
import { getDefaultTypeaheadOptionsFromKodif } from '../forms/FormHelpers';
import { subAppRouteNames } from '../../routes'
import { addUrlQuery, showErrorMsg } from '../../actions/uiActions';
import { sistemasPazinojumsActions, sistemasPazinojumsSelected, ALL_SISTEMAS_PAZINOJUMI_VIEWNAME } from '../../actions/sistemasPazinojumiActions';

const sadalasNames = {
    [ALL_SISTEMAS_PAZINOJUMI_VIEWNAME]: 'Visas sadaļas',
    ...subAppRouteNames
}

const sadalasOptions = {
    [ALL_SISTEMAS_PAZINOJUMI_VIEWNAME]: { nos: 'Visas sadaļas' },
    ...(Object.keys(sadalasNames).reduce((acc, r) => ({...acc, [r]: {nos: sadalasNames[r]}}), {}))
}

const sadalasRendererShort = (fieldValue) => fieldValue.map(s => sadalasNames[s.viewname]).join(', ')

const getColumns = () => {
    return [
        {
            dataField: 'teksts',
            text: 'Paziņojums',
            width: 300,
            formatter: (currentValues, _) => (<div dangerouslySetInnerHTML={{__html: currentValues }} />),
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'sadalas',
            text: 'Sadaļa',
            filterCfg: getmultiSelectFilterCfg(sadalasOptions),
            formatter: sadalasRendererShort,
            width: 110,
        }, {
            dataField: 'datums_no',
            text: 'Datums no',
            formatter: dateTimeFormatter,
            filterCfg: getDateFilterCfg(),
            width: 130,
        }, {
            dataField: 'datums_lidz',
            text: 'Datums līdz',
            formatter: dateTimeFormatter,
            filterCfg: getDateFilterCfg(),
            width: 130,
        }
    ]
}

const getFormFieldCfgs = () => {
    return [
        {
            name: 'teksts',
            label: 'Paziņojuma teksts',
            required: true,
            type: 'html'
        }, {
            name: 'sadalas',
            label: 'Sadaļas',
            required: true,
            type: 'autocomplete',
            autocompleteCfg: {
                options: getDefaultTypeaheadOptionsFromKodif(sadalasOptions),
                multiple: true,
                keyField: 'viewname',
                isValueObjStructure: true
            },
            renderer: (val, row) => {
                return <span className="display-linebreak">
                    {val.map(s => sadalasNames[s.viewname]).join('\n')}
                </span>
            },
            overridePropsFunc: (props) => {
                const changedProps = { ...props }
                const { currentVal, autocompleteCfg: { options } } = changedProps

                const lastSelectedView = Array.isArray(currentVal) && currentVal.length > 0 ? currentVal.slice(-1)[0].tah_id : null
                const isAllOptionSelected = lastSelectedView === ALL_SISTEMAS_PAZINOJUMI_VIEWNAME

                const changedOptions = options
                    .filter(s => isAllOptionSelected ? s.tah_id === ALL_SISTEMAS_PAZINOJUMI_VIEWNAME : true)

                changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, options: changedOptions}

                return changedProps
            }
        }, {
            name: 'datums_no',
            label: 'Datums no',
            required: true,
            type: 'date',
            renderer: dateTimeFormatter,
            extraFieldProps: () => ({
                showTimeSelect: true,
                timeCaption: 'Laiks'
            })
        }, {
            name: 'datums_lidz',
            label: 'Datums līdz',
            type: 'date',
            renderer: dateTimeFormatter,
            validation: (values) => {
                if (values.datums_no && values.datums_lidz && values.datums_lidz < values.datums_no) {
                    return 'Sistēmas paziņojuma beigu datums nedrīkst būt mazāks par sākuma datumu'
                }
                return null
            },
            extraFieldProps: ({currentValRow}) => ({
                minDate: new Date(currentValRow.datums_no),
                showTimeSelect: true,
                timeCaption: 'Laiks'
            })
            
        }
    ]
}

class SistemasPazinojumi extends Component {

    render() {

        const { sistemasPazinojumi } = this.props

        if (!sistemasPazinojumi || sistemasPazinojumi.loading) {
            return (<Loading/>)
        }

        if(!this.columns){
            this.columns = getColumns()
        }

        if(!this.formFieldCfgs){
            this.formFieldCfgs = getFormFieldCfgs()
        }

        return (
            <div className="saraksts-wrapper">
                <GridWithForm
                    {...this.props}
                    columns={this.columns}
                    formFieldCfgs={this.formFieldCfgs}
                    storeObj={sistemasPazinojumi}
                    formWidthGuiOption="sistemas_pazinojumi_form_width"
                    metadataFormCfg="sistemas_pazinojumi_edit"
                    tiesibasLabot="VISI"
                />
            </div>
        )
    }

}


const mapStateToProps = ({sistemasPazinojumi, metadata, location}) => ({sistemasPazinojumi, metadata, query: location.query})

export default connect(mapStateToProps, {
    selectedAct: sistemasPazinojumsSelected,
    addUrlQuery,
    ...sistemasPazinojumsActions,
    showErrorMsg
})(SistemasPazinojumi)