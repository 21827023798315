import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading';
import GridWithForm from '../grid/GridWithForm'
import {
    getTextFilterCfg, 
    getmultiSelectFilterCfg,
    getBooleanSelectFilterCfg,
    getBooleanFormatter,
} from '../grid/ColumnHelpers'
import { OTHER_VIEWNAME } from '../../constants'
import { getDefaultTypeaheadOptionsFromKodif } from '../forms/FormHelpers';
import { addUrlQuery, showErrorMsg } from '../../actions/uiActions';
import { kartesFonaSlanisActions, kartesFonaSlanuAdministresanaSelected } from '../../actions/fonaSlanuAdministresanaActions';
import { FormGroup } from 'reactstrap';
import { InputField } from '../forms/Form';
import { subAppRouteNames } from '../../routes';
import TypeaheadField from '../forms/TypeaheadField';
import { UncontrolledTooltip } from 'reactstrap';

const sadalasNames = {
    [OTHER_VIEWNAME]: 'Visas sadaļas',
    ...subAppRouteNames
}

const sadalasOptions = {
    [OTHER_VIEWNAME]: { nos: 'Visas sadaļas' },
    ...(Object.keys(sadalasNames).reduce((acc, r) => ({...acc, [r]: {nos: sadalasNames[r]}}), {}))
}

const isRowSlanis = (currentValues) => currentValues.vai_ir_slanis === true
const isRowMainLayer = (currentValues) => {
    const isEmpty = (val) => [null, undefined, ''].indexOf(val) !== -1
    
    return isEmpty(currentValues.id) === false && isEmpty(currentValues.parent_id) && isEmpty(currentValues.parent_nosaukums)
}
const sadalasRendererShort = (fieldValue) => fieldValue.length > 0 ? fieldValue.map(s => sadalasNames[s.viewname]).join(', ') : 'Nav sadaļas'

const renderHierarchy = (levels, depth = 0) => {
    if (levels.length === 0) return null

    const isLast = levels.length === 1;
    const prefix = isLast ? '└─ ' : '├─ ';
    const lineSymbol = !isLast && depth > 0 ? '│ ' : ' ';

    return (
        <div key={depth} className="ml-1">
            <div>
                {depth > 0 && <span>{lineSymbol.repeat(depth - 1)}</span>}
                <span>{prefix}{levels[0]}</span>
            </div>
            <div className="ml-1">
                {renderHierarchy(levels.slice(1), depth + 1)}
            </div>
        </div>
    )
}

const renderSlaniWithNosaukumsDepth = (slanisWithParentNosaukums, removeLastChild = false) => {
    let hierarchyLevels = slanisWithParentNosaukums.split(' $| ')
    if (removeLastChild === true) {
        hierarchyLevels.pop()
    }
    return (
        <div>
            <span>{hierarchyLevels[0]}</span>
            {renderHierarchy(hierarchyLevels.slice(1), 1)}
        </div>
    )
}

const renderLastHierarchyName = (slanisWithParentNosaukums, removeLastChild = false) => {
    let hierarchyLevels = slanisWithParentNosaukums.split(' $| ')
    if (removeLastChild === true) {
        hierarchyLevels.pop()
    }
    return hierarchyLevels.length > 0 ? hierarchyLevels[hierarchyLevels.length - 1] : slanisWithParentNosaukums
}

const getColumns = () => {
    return [
        {
            dataField: 'nosaukums_with_parent',
            text: 'Nosaukums',
            formatter: (currentValues, _) => {
                return renderSlaniWithNosaukumsDepth(currentValues)
            },
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'sort_order',
            text: 'Secība',
            headerClasses: 'align-top',
            width: 70
        }, {
            dataField: 'sadalas',
            text: 'Sadaļa',
            filterCfg: getmultiSelectFilterCfg(sadalasOptions),
            formatter: sadalasRendererShort,
            width: 110,
        }, {
            dataField: 'var_labot',
            text: 'Vai var labot slāņa datus?',
            formatter: getBooleanFormatter(),
            filterCfg: getBooleanSelectFilterCfg(),
            width: 82
        }
    ]
}

const getFormFieldCfgs = () => {
    return [
        {
            name: 'nosaukums',
            label: 'Nosaukums',
            required: true,
            type: 'text'
        }, {
            name: 'sort_order',
            label: 'Secība mapītē',
            type: 'number',
            overridePropsFunc: (props) => {
                const changedProps = { ...props }
                changedProps.extraFieldProps = {
                    disabled: props.currentValues.var_labot === false
                }
                return changedProps
            }
        }, {
            name: 'parent_id',
            label: 'Atrodas slāņu mapītē',
            type: 'autocomplete',
            overridePropsFunc: (props) => {
                const changedProps = {...props}
                const {currentValues} = props

                changedProps.autocompleteCfg = {
                    initialOptionsViewName: 'fona_slanu_administresana_parent_search',
                    labelFieldName: 'nosaukums_with_parent',
                    extraFilterParams: {
                        ...(currentValues.id ? {not_fona_slanis: currentValues.id } : {}) 
                    }
                }

                const isMainLayer = isRowMainLayer(currentValues)

                changedProps.extraFieldProps = {
                    disabled: isMainLayer,
                    labelKey: (row) => {
                        return `${renderLastHierarchyName(row.tah_label)}`
                    },
                    renderMenuItemChildren: (row) => {
                        return renderSlaniWithNosaukumsDepth(row.nosaukums_with_parent)
                    }
                }

                return changedProps
            },
            renderer: (_, row) => {
                return row.parent_id === null ? '-' : renderSlaniWithNosaukumsDepth(row.nosaukums_with_parent, true)
            }
        }, {
            name: 'sadalas',
            label: 'Sadaļas, kurās iekļauts',
            renderer: (val, row) => {
                return <>
                    {val.length > 0 ? val.map(s => {
                        return (
                            <span key={s.viewname} className="d-block display-linebreak">
                                {sadalasNames[s.viewname]}
                                &nbsp;&nbsp;({getBooleanFormatter(false, 'Pēc noklusējuma ieslēgts', 'Pēc noklusējuma izslēgts')(s.vai_ieslegta_sadala)})
                            </span>
                        )
                    }) : 
                        <span key="empty_sadala" className="d-block display-linebreak">
                            Nav sadaļas
                        </span>
                    }
                </>
            },
            multiField: true,
            customField: (props) => {
                const {fieldName, currentVal, currentValues, multiFieldArrInd} = props
                const selectedSadalas = currentValues.sadalas.map(s => s.viewname).filter(Boolean)

                const changedOptions = getDefaultTypeaheadOptionsFromKodif(sadalasOptions)
                    .map(o => ({
                        ...o,
                        disabled: selectedSadalas.includes(o.tah_id)
                    }))

                const canEdit = props.currentValues.var_labot === true

                const rowType = isRowSlanis(currentValues) ? 'slānis' : 'mapīte'
                const ieslegtsLabel = isRowSlanis(currentValues) ? 'ieslēgts' : 'ieslēgta'
                const toolTipMessage = `Nosaka, vai ${rowType} ir pēc noklusējuma ${ieslegtsLabel} norādītajā sadaļā`

                return <>
                    <FormGroup check inline>
                        <TypeaheadField
                            {...props}
                            extraFieldProps={{
                                disabled: !canEdit
                            }}
                            fieldName={`${fieldName}.viewname`}
                            currentVal={currentVal.viewname}
                            autocompleteCfg={{
                                options: changedOptions,
                                keyField: 'viewname'
                            }}
                        />
                    </FormGroup>
                    <FormGroup check inline>
                        <InputField
                            {...props}
                            extraFieldProps={{
                                disabled: !canEdit
                            }}
                            currentVal={currentVal.vai_ieslegta_sadala}
                            fieldName={`${fieldName}.vai_ieslegta_sadala`}
                            fieldType="checkbox"
                        />
                        <span id={`sadalas-${multiFieldArrInd}`} className="ml-2 fa fa-question-circle" style={{ color: '#31669c' }}/>
                        <UncontrolledTooltip 
                            placement="right"
                            target={`sadalas-${multiFieldArrInd}`}
                        >
                            {toolTipMessage}
                        </UncontrolledTooltip>
                    </FormGroup>
                </>
            },
            overridePropsFunc: (props) => {
                const changedProps = { ...props }
                const canEdit = props.currentValues.var_labot
                changedProps.extraFieldProps = {
                    canAdd: canEdit,
                    canEditRow: canEdit,
                    canRemove: canEdit
                }
                return changedProps
            },
            createNewRow: () => ({vai_ieslegta_sadala: true}) 
        }
    ]
}


class FonaSlanuAdministresanaSaraksts extends Component {

    render() {

        const { deleteAct, createAct, kartesFonaSlani } = this.props

        const { selectedItem } = kartesFonaSlani
        
        if (!kartesFonaSlani || kartesFonaSlani.loading) {
            return (<Loading/>)
        }

        if(!this.columns){
            this.columns = getColumns()
        }

        if(!this.formFieldCfgs){
            this.formFieldCfgs = getFormFieldCfgs()
        }

        // Can delete only those folders ("mapites") that are not one of the main parents
        const canDelete = selectedItem && selectedItem.var_labot && !isRowMainLayer(selectedItem) && !isRowSlanis(selectedItem)

        return (
            <GridWithForm
                {...this.props}
                columns={this.columns}
                formFieldCfgs={this.formFieldCfgs}
                storeObj={kartesFonaSlani}
                createAct={createAct}
                deleteAct={canDelete ? deleteAct : null}
                formWidthGuiOption="fona_slanu_administresana_form_width"
                metadataFormCfg="fona_slanu_administresana_edit"
                extraGridProps={this.extraGridProps}
                tiesibasLabot="FONA_SLANU_ADMINISTRATORS"
            />
        )
    }

}


const mapStateToProps = ({kartesFonaSlani, metadata, location}) => ({kartesFonaSlani, metadata, query: location.query})

export default connect(mapStateToProps, {
    selectedAct: kartesFonaSlanuAdministresanaSelected,
    addUrlQuery,
    ...kartesFonaSlanisActions,
    showErrorMsg
})(FonaSlanuAdministresanaSaraksts)