import React, {Component} from 'react';
import {connect} from 'react-redux'
import Grid from '../grid/Grid'
import {
    getTextFilterCfg,
    getDateFilterCfg,
    getEditIconColumn,
    dateFormatter
} from '../grid/ColumnHelpers'
import {openAdServiss} from '../../actions/adServisiActions'


const editIconColumn = getEditIconColumn((row) => (
    {
        type: 'loc_ad_serviss',
        payload: {
            id: row.id
        }
    }
    )
)

export const getColumns = ({kodif, user}) => {
    const cols = [
        editIconColumn,
        {
            dataField: 'nosaukums',
            text: 'Nosaukums',
            filterCfg: getTextFilterCfg(),
        }, {
            dataField: 'labošanas_datums',
            text: 'Labošanas datums',
            filterCfg: getDateFilterCfg(),
            formatter: dateFormatter
        }, {
            dataField: 'labotājs',
            text: 'Labotājs',
            filterCfg: getTextFilterCfg(),
            formatter: (value) => {
                return value?.personas_dati
              },
            sort: false
        }
  ]
  return cols
}

class AdServisuSaraksts extends Component {

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
           this.props.openAdServiss(row.id)
        }
    }

    render() {

        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif,
                user: this.props.user
            })
        }

        const {loading, list, count} = this.props.ad_servisi

        return (
            <div className="saraksts-wrapper">
                <Grid
                    loading={loading}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >
                </Grid>
            </div>
        )
    }

}

const mapStateToProps = ({ad_servisi, kodif, user}) => ({ad_servisi, kodif, user})

export default connect(mapStateToProps, {openAdServiss})(AdServisuSaraksts)
