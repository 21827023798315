import React, {Component} from 'react'
import {connect} from 'react-redux'
import Grid from '../grid/Grid'
import {
    getSelectFilterCfg,
    getTextFilterCfg,
    getDateFilterCfg,
    dateTimeFormatter,
    getBadgeFormatterForKodif,
    getmultiSelectFilterCfg,
    getFormatterForKodif,
} from '../grid/ColumnHelpers'
import Loading from '../Loading'

export const getColumns = ({kodif, metadata}) => {
    return [
        {
            dataField: 'service',
            text: 'Serviss',
            filterCfg: getmultiSelectFilterCfg(kodif.adRemoteAuditServiss),
            formatter: getFormatterForKodif(kodif.adRemoteAuditServiss)
        }, {
            dataField: 'success',
            text: 'Statuss',
            width: 120,
            filterValue: (cell, row) => (cell ? 'success' : 'error'),
            filterCfg: getSelectFilterCfg(kodif.izmainuVesturesStatuss),
            formatter: (cell, row) => {
                const value = cell ? 'success' : 'error';
                return getBadgeFormatterForKodif(kodif.izmainuVesturesStatuss)(value, row);
              }
        }, {
            dataField: 'request_time',
            text: 'Pieprasījuma laiks',
            formatter: dateTimeFormatter,
            filterCfg: getDateFilterCfg()
        }, {
            dataField: 'response_time',
            text: 'Atbildes laiks',
            formatter: dateTimeFormatter,
            filterCfg: getDateFilterCfg()
        }, {
            dataField: 'request_url',
            text: 'Pieprasījuma URL',
            filterCfg: getTextFilterCfg()
        }, 
  ]};

export const getExpandRowDef = () => ({
    renderer: (row) => (
      <div>
        <div><strong>Pieprasījums:</strong></div>
        <pre>{row.request}</pre>
        <div><strong>Atbilde:</strong></div>
        <pre>{row.response}</pre>
      </div>
    ),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (isAnyExpands ?
      <i className="fa fa-minus-square expand-collapse-button"></i>
      : <i className="fa fa-plus-square expand-collapse-button"></i>),
    expandColumnRenderer: ({ expanded }) => (expanded ?
      <i className="fa fa-minus-square expand-collapse-button-row"></i>
      : <i className="fa fa-plus-square expand-collapse-button-row"></i>
    ),
  });

class AdAudits extends Component {

      render() {

        const {metadata} = this.props

        if (!metadata.loaded) {
            return (<Loading/>)
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif,
                metadata
            })
        }

        if(!this.expandRow){
            this.expandRow = getExpandRowDef({
                metadata
            })
        }

        const {loading, list, count} = this.props.ad_audits

        return (
            <div className="saraksts-wrapper">
                <Grid
                    loading={loading}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                    expandRow={this.expandRow}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ad_audits, kodif, metadata}) => ({ad_audits, kodif, metadata})
export default connect(mapStateToProps)(AdAudits)
