import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typeahead, AsyncTypeahead, Token, Highlighter } from 'react-bootstrap-typeahead'
import _ from 'lodash'
import { searchAction } from '../../actions/helpers'

class TypeaheadField extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            options: null
        }
        this.lastExtraFilterParams = ""
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { currentVal, fieldType, errorForField, isTouchedField, extraFieldProps, autocompleteCfg, fieldName } = this.props
        const { isLoading, options } = this.state
        return (currentVal !== nextProps.currentVal
            || fieldType !== nextProps.fieldType
            || fieldName !== nextProps.fieldName
            || errorForField !== nextProps.errorForField
            || isTouchedField !== nextProps.isTouchedField
            || !_.isEqual(extraFieldProps, nextProps.extraFieldProps)
            || !_.isEqual(autocompleteCfg, nextProps.autocompleteCfg)
            || isLoading !== nextState.isLoading
            || !_.isEqual(options, nextState.options))
    }


    componentDidMount() {
        this.getRemoteStaticOptions()
    }

    onChange = (selected) => {
        const { setFieldValue, autocompleteCfg, fieldName } = this.props
        const { multiple, keyField, allowNew, labelFieldName } = autocompleteCfg
        const isValueObjStructure = this.getIsValueObjStructure({ autocompleteCfg })

        const getSingleFieldValue = (v) => {
            if (isValueObjStructure) {
                if (allowNew && v.customOption) {
                    const fv = { [keyField || 'id']: null }
                    if (labelFieldName) {
                        fv[labelFieldName] = v.tah_label
                    }
                    return fv
                }
                return { ...v, id: undefined, [keyField || 'id']: v.tah_id }
            } else {
                if (allowNew && v.customOption) {
                    return v.tah_label
                }
                return v.tah_id
            }
        }
        if (selected && selected.length > 0) {
            if (multiple === true) {
                setFieldValue(fieldName, selected.map(getSingleFieldValue), this.lastOptions)
            } else {
                setFieldValue(fieldName, getSingleFieldValue(selected[0]), this.lastOptions)
            }
        } else {
            if (multiple === true) {
                setFieldValue(fieldName, [], this.lastOptions)
            } else {
                setFieldValue(fieldName, null, this.lastOptions)
            }
        }
    }

    isEmptyCurrentVal = () => {
        const { currentVal } = this.props
        return currentVal == null || (Array.isArray(currentVal) && currentVal.length === 0)
    }

    onBlur = (e) => {
        const { setFieldTouched, fieldName } = this.props
        if (setFieldTouched) {
            setFieldTouched(fieldName, true)
        }
        // clear typeahead if no value selected
        if (this.isEmptyCurrentVal()) {
            this.typeahead.clear()
        }
        // if typeahead input field has value, add it automatically to selected items
        if (this.lastOptions && e.target && e.target.value) {
            const selected = this.findOptionByText({ val: e.target.value, options: this.lastOptions })
            const { currentVal } = this.props
            if (selected && (this.isEmptyCurrentVal() || currentVal !== selected.tah_id)) {
                if (!this.isEmptyCurrentVal() && Array.isArray(currentVal)) {
                    this.onChange([...currentVal, selected])
                } else {
                    this.onChange([selected])
                }
            }
        }
    }

    componentDidUpdate() {
        this.getRemoteStaticOptions()
    }

    getRemoteStaticOptions = () => {
        const { autocompleteCfg } = this.props
        const { initialOptionsViewName, extraFilterParams } = autocompleteCfg
        const strExtraFilterParams = JSON.stringify(extraFilterParams)
        if (initialOptionsViewName && extraFilterParams && strExtraFilterParams !== this.lastExtraFilterParams) {
            this.onRemoteSearch('')
            this.lastExtraFilterParams = strExtraFilterParams
        }
    }

    onRemoteSearch = (query) => {
        this.setState({ isLoading: true })
        const { extraFilterParams, remoteSearchViewName, initialOptionsViewName } = this.props.autocompleteCfg
        this.props.searchAction(query, this, remoteSearchViewName || initialOptionsViewName, extraFilterParams, initialOptionsViewName ? 1000 : 20)
    }

    onRemoteSuccess = (options) => {
        this.setState({
            isLoading: false,
            options
        })
    }

    onRemoteFailure = () => {
        this.setState({ isLoading: false, options: [] })
    }

    findOptionByText = ({ val, options }) => {
        return options.find((v) => v.tah_id === val || v.tah_label === val)
    }

    findValue = ({ val, options }) => {
        const { autocompleteCfg } = this.props
        const { keyField } = autocompleteCfg
        const isValueObjStructure = this.getIsValueObjStructure({ autocompleteCfg })
        if (isValueObjStructure) {
            return options.find((v) => v.tah_id === val[keyField || 'id'])
        }
        return options.find((v) => v.tah_id === val)
    }

    getIsValueObjStructure = ({ autocompleteCfg }) => autocompleteCfg.isValueObjStructure || (autocompleteCfg.multiple && autocompleteCfg.isValueObjStructure !== false)

    render() {
        const { autocompleteCfg, fieldName, currentVal, errorForField, isTouchedField, autoFocus, dataObj, currentValRow } = this.props
        const { multiple, keyField, remoteSearchViewName, allowNew, labelFieldName, initialOptionsViewName } = autocompleteCfg
        const extraFieldProps = this.props.extraFieldProps || {}

        const isValueObjStructure = this.getIsValueObjStructure({ autocompleteCfg })

        const selected = []
        let options = this.state.options != null ? this.state.options : autocompleteCfg.options
        if (!options && (remoteSearchViewName || initialOptionsViewName)) {
            options = []
        }

        let someValuesAreNotFound = false
        if (currentVal) {
            if (multiple === true) {
                if (Array.isArray(currentVal)) {
                    currentVal.forEach(val => {
                        const found = this.findValue({ val, options })
                        if (found) {
                            selected.push({ ...val, ...found })
                        } else if (remoteSearchViewName || initialOptionsViewName) {
                            //for remote autocomplete add automatically initial values
                            const ds = { ...val, tah_id: val[keyField || 'id'] }
                            selected.push(ds)
                        } else {
                            someValuesAreNotFound = true
                        }
                    })
                } else {
                    //we suppose it's string seperated with comma
                    currentVal.split(',').forEach(spl => {
                        const splProc = spl.trim()
                        const found = options.find((v) => v.tah_id === splProc)
                        if (found) {
                            selected.push(found)
                        } else {
                            someValuesAreNotFound = true
                        }
                    })
                }
            } else {
                const defVal = this.findValue({ val: currentVal, options })
                if (defVal) {
                    selected.push(defVal)
                } else if (remoteSearchViewName || initialOptionsViewName) {
                    //for remote autocomplete add automatically initial values
                    if (isValueObjStructure) {
                        const ds = { ...currentVal, tah_id: currentVal[keyField || 'id'] }
                        if (labelFieldName && !currentVal.tah_label) {
                            ds.tah_label = currentVal[labelFieldName]
                        }
                        // skip adding when tah label is empty and skipFromSelected=true
                        if (ds.tah_label || !ds.skipFromSelected) {
                            selected.push(ds)
                        }
                    } else {
                        const ds = { tah_id: currentVal }
                        if (labelFieldName && !currentVal.tah_label) {
                            if (currentValRow) {
                                ds.tah_label = currentValRow[labelFieldName]
                            } else {
                                ds.tah_label = dataObj[labelFieldName]
                            }
                        }
                        selected.push(ds)
                    }
                } else {
                    someValuesAreNotFound = true
                }
            }
        }

        if (someValuesAreNotFound) {
            setTimeout(() => {
                this.onChange(selected)
            }, 50)
        }

        const highlightOnlyResult = !allowNew
        // labelKey accepts only string value, remove html tags
        const labelKey = !allowNew ? (option) => (option && option.ir_html ? option.tah_label.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp/g, ' ') : option ? option.tah_label : '') : 'tah_label'

        const cfg = {
            id: fieldName,
            clearButton: true,
            name: fieldName,
            labelKey,
            renderToken: (option, props, index) => {
                const className = option && option.ir_arhivets ? "cross-out" : ""
                const is_html = option && option.ir_html
                const isDisabled = option && option.disabled
                return (
                    <Token title={errorForField || ''} disabled={isDisabled} key={index} onRemove={props.onRemove} option={option}>
                        {is_html ? (<span dangerouslySetInnerHTML={{ __html: option.tah_label }} />) : (<span className={className}>{option.tah_label}</span>)}
                    </Token>
                )
            },
            renderMenuItemChildren: (option, props, index) => {
                const className = option && option.ir_arhivets ? "cross-out" : ""
                const is_html = option && option.ir_html
                return (
                    <span className={className}>
                        {is_html ? <span className="html-inside" dangerouslySetInnerHTML={{ __html: option.tah_label }} /> :
                            <Highlighter key="name" search={props.text}>
                                {option.tah_label}
                            </Highlighter>
                        }
                    </span>
                )
            },
            autoFocus: autoFocus === true ? autoFocus : false,
            onChange: this.onChange,
            isInvalid: !!errorForField && !!isTouchedField,
            onBlur: this.onBlur,
            onInputChange: this.onInputChange,
            multiple,
            emptyLabel: 'Nav atrasti',
            allowNew: !!allowNew,
            inputProps: { title: errorForField || '' },
            flip: true,
            positionFixed: true,
            selectHint: (shouldSelectHint, e) => {
                return e.key === 'Enter' || shouldSelectHint; // should select only if enter is pressed or is selectable
            },
            newSelectionPrefix: 'Izveidot jaunu:',
            highlightOnlyResult,
            ref: (typeahead) => this.typeahead = typeahead,
            align: 'left',
            ...extraFieldProps,
            options,
            selected
        }

        this.lastOptions = cfg.options

        if (remoteSearchViewName) {
            const cfgRemote = {
                promptText: 'Ievadiet',
                searchText: 'Meklē...',
                isLoading: this.state.isLoading,
                onSearch: this.onRemoteSearch,
                filterBy: (option, props) => true,
                useCache: false,
                ...cfg
            }
            return <AsyncTypeahead {...cfgRemote} />
        } else {
            return <Typeahead {...cfg} />
        }
    }
}

export default connect(() => ({}), { searchAction })(TypeaheadField)
