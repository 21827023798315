import * as helpers from './helpers'

const defaultListReducerForKartesFonaSlanuAdministresana = helpers.createListReducer({listViewName: 'fona_slanu_administresana_edit', actionsName: 'kartesFonaSlanis'})
const defaultEditReducerForKartesFonaSlanuAdministresana = helpers.createEditReducer({editViewName: 'fona_slanu_administresana_edit', actionsName: 'kartesFonaSlanis'})
export const kartesFonaSlani = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForKartesFonaSlanuAdministresana({state, type, payload, reqId})
  return defaultEditReducerForKartesFonaSlanuAdministresana({state: stateAfterList, type, payload, reqId})
}

const defaultListReducerForKartesFonaSlanuAdministresanaByLocation = helpers.createListReducer({listViewName: 'fona_slanu_administresana_view_search'})
export const kartesFonaSlaniByLocation = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  return defaultListReducerForKartesFonaSlanuAdministresanaByLocation({state, type, payload, reqId})
}