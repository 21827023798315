import React, { PureComponent } from 'react'

import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";

class ExportSheetsButton extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      loadingPopup: false
    }
  }
n
  toggleModal = (visible) => {
    this.setState({
      loadingPopup: visible
    })
  }

  exportOds = async () => {
    this.toggleModal(true)
    const buffer = await this.props.sheetsData.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `virszemes_ekologija_data_${this.props.gads}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.toggleModal(false)
  }

  render() {
    return <>
      <Button color="secondary" onClick={this.exportOds}><i className="fa fa-file-excel-o"></i>&nbsp;&nbsp;Eksportēt</Button>
      <Modal
        isOpen={this.state.loadingPopup}
        className="modal-sm">
        <ModalHeader>Notiek datu sagatavošana</ModalHeader>
        <ModalBody className="text-center">
          <Spinner size="lg" color="primary" />{' '}
        </ModalBody>
      </Modal>
    </>
  }
}

export default ExportSheetsButton