import {
    callApi,
    getDefaultEditActions
} from './helpers'

export const ALL_SISTEMAS_PAZINOJUMI_VIEWNAME = 'all';

export const loadSistemasPazinojumi = (query) => (dispatch, getState) => {
  return dispatch(callApi({
    viewName: 'sistemas_pazinojumi_edit',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const sistemasPazinojumsSelected = (id) => {
  return { type: 'sistemasPazinojums_selected', payload : id }
}

const sistemasPazinojumsAfterSaveOrDelete = (dispatch, getState) => {
  dispatch(loadSistemasPazinojumi(getState().location.query))
}

export const sistemasPazinojumsActions = getDefaultEditActions({
  actionTypeName : 'sistemasPazinojums',
  stateName: 'sistemasPazinojumi',
  combinedPage: true,
  apiViewName: 'sistemas_pazinojumi_edit',
  afterDelete: sistemasPazinojumsAfterSaveOrDelete,
  afterSave: sistemasPazinojumsAfterSaveOrDelete,
})

export const loadSistemasPazinojumiByLocation = (query) => (dispatch) => {
  // If an empty query is provided, load the "sistemas pazinojumi" rows that are visible in "all" views
  const isQueryDefined = [null, undefined, ''].indexOf(query) === -1
  const filters = { 
    view: isQueryDefined ? query : ALL_SISTEMAS_PAZINOJUMI_VIEWNAME
  }
  return dispatch(callApi({
    viewName: 'sistemas_pazinojumi_view_search',
    crudMethod: 'list',
    crudParam: {...query, f: JSON.stringify(filters)}
  }))
}