import * as helpers from './helpers'

const defaultListReducerForSistemasPazinojumi = helpers.createListReducer({listViewName: 'sistemas_pazinojumi_edit', actionsName: 'sistemasPazinojums'})
const defaultEditReducerForSistemasPazinojumi = helpers.createEditReducer({editViewName: 'sistemas_pazinojumi_edit', actionsName: 'sistemasPazinojums'})

export const sistemasPazinojumi = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForSistemasPazinojumi({state, type, payload, reqId})
  return defaultEditReducerForSistemasPazinojumi({state: stateAfterList, type, payload, reqId})
}

const defaultListReducerForSistemasPazinojumiByLocation = helpers.createListReducer({listViewName: 'sistemas_pazinojumi_view_search'})

export const sistemasPazinojumiByLocation = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  return defaultListReducerForSistemasPazinojumiByLocation({state, type, payload, reqId})
}